import React, { useState, useEffect, useContext } from 'react';
import { SplashScreen } from './components/screens/index';
import { RoutesComponent } from './routes/Route';
import { StoreContext } from './components/context/store-context';
/**
 *This function returns app and and handles the the splash screen time to be render
 */
function App() {
  const storeCtx = useContext(StoreContext);
  // add logo animation need to finish working on it
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] =
    useState(true);

  useEffect(() => {
    if (isShowingSplashAnimation) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        3000 // time of rendering
      );
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);

  return (
    <div>
      {isShowingSplashAnimation ? <SplashScreen /> : <RoutesComponent />};
    </div>
  );
}

export default App;
