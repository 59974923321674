import http from '../api/http-common';
/**
 * This file contains everything related to Aip fetching and useQuery for catching an api called.
 */

/**
 * Fetches only one store
 * @param  {object} storePort - this object contains the port of the store and headers for api fetch
 * @return {Object } ResponseData - the Api called
 */
export const fetchStoreInfo = async (storePort) => {
  console.log('port ', storePort);
  const response = await http.post('/get-storeInfo.php', storePort);
  console.log('data ', response?.data);
  return response.data;
};
/**
 * Function fetches all stores from kwickbiz table
 * @return {object} ResponseData - the Api called
 */
export const fetchStoresInfo = async () => {
  console.log('stores');
  const response = await http.get('/storesInfoApi.php');
  console.log('stores response', response.data);

  return response.data;
};
/**
 * @param  {object} waitingList - data to be inserted into waiting table
 *  @return {object} ResponseData - if it was inserted into db
 */
export const addToWaitListApi = async (waitingList) => {
  try {
    const response = await http.post('/add-waiting.php', waitingList);

    return response.status;
  } catch (error) {
    console.log(error);
  }
};
/**
 * @param  {object} storeReservation - data to be inserted into waiting table
 * @returns {object} - data if it was inserted into db
 */
export const addReservationApi = async (storeReservation) => {
  try {
    const response = await http.post('/add-reservation.php', storeReservation);
    return response.status;
  } catch (error) {
    console.log(error);
  }
};
/**
 * @param  {object} data - data to be inserted into smslog table
 * @returns {object} -data if it was inserted
 */
export const addSmsApi = async (data) => {
  try {
    const response = await http.post('/send-sms.php', data);
    return response.status;
  } catch (error) {
    console.log(error);
  }
};
