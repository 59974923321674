import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import StoreContextProvider from './components/context/store-context';
// Importing UiSemantic CSS

import 'semantic-ui-css/semantic.min.css';

ReactDOM.render(
  <StoreContextProvider>
    <App />
  </StoreContextProvider>,

  document.getElementById('root')
);
