import React, { useState } from 'react';
import { addReservationApi, addSmsApi } from '../api/PostsWithAxios';
import { RemoveDashes } from './Helpers/GetPhone';
import './components.css';

import {
  Button,
  Icon,
  Input,
  Modal,
  Grid,
  Message,
  Loader,
  Dimmer,
  Form,
  Label,
} from 'semantic-ui-react';
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size';
import Confetti from 'react-confetti';
import 'react-multi-carousel/lib/styles.css';

import { FormatDate } from './Helpers/FormatDate';
import { useInput } from './customHooks/use-input';
/**
 * This component will show a modal of text inputs for when making a reservation
 * @component
 * @example
 * const isOpen = true;
 * const timeSelected = 11:00 PM;
 * const toggleModal = true;
 * const date = '03-03-2022';
 * const people = '2';
 * const port = 23455
 * const storePhone = 123-456-7677;
 *
 * return (
 *  <ReservationModal
 *        isOpen={isOpen}
 *        timeSelected={timeSelected}
 *        toggleModal={toggleModal}
 *        date={date}
 *        people={people}
 *        port={port}
 *        storePhone={storePhone}
 *      />
 * )
 *
 */
export const ReservationModal = ({
  people,
  date,
  port,
  storePhone,
  storeName,
  isOpen,
  timeSelected,
  toggleModal,
  siteName,
}) => {
  const [secondOpen, setSecondOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [storeResponse, setStoreResponse] = useState('');
  const [StoreReservation, setStoreReservation] = useState();

  let storeReservation;
  var temp = date.toString();
  let newDate = temp.substring(0, 16);
  const [width, height] = useWindowSize();

  const onlyWidth = useWindowWidth();
  const onlyHeight = useWindowHeight();

  const {
    value: email,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailInput,
  } = useInput('email');
  const {
    value: name,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetNameInput,
  } = useInput('text');
  const {
    value: phone,
    isValid: enteredPhoneIsValid,
    hasError: phoneInputHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    reset: resetPhoneInput,
  } = useInput('phone');

  let formInvalid = false;
  if (enteredNameIsValid && enteredEmailIsValid && enteredPhoneIsValid) {
    formInvalid = true;
  }

  const HandleSubmit = async (event) => {
    toggleModal(false);
    event.preventDefault();
    if (!enteredNameIsValid) {
      return;
    }

    const finalDate = FormatDate(newDate, timeSelected);

    storeReservation = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      user_name: name,
      user_email: email,
      user_phone: RemoveDashes(phone),
      user_guests: people,
      user_reservation: finalDate,
      store_port: port === undefined || null ? '0001' : port,
      site_name: siteName,
    };

    setStoreReservation({
      user_name: name,
      user_email: email,
      user_phone: phone,
      user_guests: people,
      user_reservation: finalDate,
    });

    let smsData = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      site_name: siteName,
      user_phone: RemoveDashes(phone),
      txt_message:
        'Reservation on ' +
        newDate +
        ' At ' +
        timeSelected +
        ' For ' +
        people +
        ' Guests' +
        ' at ' +
        storeName,
    };
    setSecondOpen(true);
    setIsLoading(true);
    try {
      const reservationRes = await addReservationApi(storeReservation);
      const smsConfirmation = await addSmsApi(smsData);
      setStoreResponse(reservationRes);
      setIsLoading(false);
    } catch (error) {
      setStoreResponse(error);
      console.log('error', error);
      setIsLoading(false);
    }
    resetEmailInput();
    resetNameInput();
    resetPhoneInput();
  };

  return (
    <div>
      <Modal
        label={{
          as: 'a',
          color: 'black',
          content: 'Hotel',
          icon: 'hotel',
          ribbon: true,
        }}
        closeIcon
        className="modal"
        dimmer="blurring"
        onClose={() => {
          if (isOpen) {
            toggleModal(false);
          }
        }}
        centered={false}
        open={isOpen}
      >
        <Modal.Header>
          <Grid textAlign="left">
            <Grid.Column>
              <Label as="a" color="red" ribbon>
                <p style={{ fontSize: '12px' }}>KwickTable</p>
              </Label>

              <h3 style={{ textAlign: 'center' }}>
                Reservation <Icon size="small" name="food" circular />
              </h3>
            </Grid.Column>
          </Grid>
        </Modal.Header>

        <Grid textAlign="left">
          <Grid.Column>
            <Modal.Content>
              <Form onSubmit={HandleSubmit}>
                <h4
                  style={{
                    fontSize: '18px',
                    paddingBottom: 6,
                    paddingBottom: 6,
                    textAlign: 'center',
                  }}
                >
                  {storeName}
                </h4>
                <hr />
                <h4>
                  Reservation on {newDate} At {timeSelected} For {people} Guest
                </h4>
                <Form.Field inline>
                  <Input
                    label={{ icon: 'user', content: 'Name' }}
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                    value={name}
                  />

                  {nameInputHasError && (
                    <Label basic color="red">
                      Please enter a valid name
                    </Label>
                  )}
                </Form.Field>
                <Form.Field inline>
                  <Input
                    label={{ icon: 'mail', content: 'Email' }}
                    onChange={emailChangeHandler}
                    onBlur={emailBlurHandler}
                    value={email}
                  />

                  {emailInputHasError && (
                    <Label basic color="red">
                      Please enter a valid email
                    </Label>
                  )}
                </Form.Field>
                <Form.Field inline>
                  <Input
                    label={{ icon: 'phone', content: 'Phone' }}
                    onChange={phoneChangeHandler}
                    onBlur={phoneBlurHandler}
                    value={phone}
                  />
                  {phoneInputHasError && (
                    <Label basic color="red">
                      Please enter a valid phone number
                    </Label>
                  )}
                </Form.Field>

                <Button
                  disabled={!formInvalid}
                  onClick={HandleSubmit}
                  type="submit"
                  color="red"
                >
                  Submit <Icon name="right chevron" />{' '}
                </Button>
              </Form>
            </Modal.Content>
          </Grid.Column>
        </Grid>
      </Modal>

      <Modal
        closeIcon
        dimmer="blurring"
        onClose={() => setSecondOpen(false)}
        open={secondOpen}
        size="small"
        label={{
          as: 'a',
          color: 'red',
          content: <p style={{ fontSize: '12px' }}>KwickTable</p>,

          ribbon: true,
        }}
      >
        {/* <Label as="a" color="red" ribbon>
          <p style={{ fontSize: "12px" }}>KwickTable</p>
        </Label> */}
        <Modal.Header>
          <Grid textAlign="left">
            <Grid.Column>
              <Label as="a" color="red" ribbon>
                <p style={{ fontSize: '12px' }}>KwickTable</p>
              </Label>
              <h3 style={{ textAlign: 'center' }}>
                <Icon size="small" name="list" circular />
                Reservation details
              </h3>
            </Grid.Column>
          </Grid>
        </Modal.Header>

        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <h4
                style={{
                  fontSize: '18px',
                }}
              >
                {storeName}
              </h4>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <hr />
        <Modal.Content>
          {isLoading ? (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          ) : storeResponse === 200 ? (
            <div>
              <Confetti width={650} />
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <h4>
                      Reservation for {StoreReservation?.user_name} on {newDate}{' '}
                      At {timeSelected} For {people} Guest{' '}
                    </h4>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Message
                success
                header="Success"
                content="Your Reservation was Successful"
              />
            </div>
          ) : (
            <a href={`tel:${storePhone}`}>
              <Icon color="black" name="phone" size="small" />
              <h6>{`Your Reservation was Not Successful Please call us at ${storePhone}`}</h6>
            </a>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="check"
            content="All Done"
            onClick={() => {
              if (isOpen) {
                toggleModal(false);
              }
              setSecondOpen(false);
            }}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};
