import { convertTime12to24 } from './GetHours';
/**
 * This function helps format the store hours to 12 hours
 * @param  {string} date string of the date
 * @param  {string} time string of the the time
 * @returns {string} string with 12hr date
 */
export const FormatDate = (date, time) => {
  let result;

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let selectedMonth = date.substring(4, 7);
  let selectedNumDay = date.substring(8, 10);
  let selectedYear = date.substring(11, 15);
  result = selectedYear + '-';
  months.map((month, index) => {
    if (selectedMonth === month) {
      result += index + 1;
    }
    return result;
  });
  // format ready
  result += '-' + selectedNumDay + ' ' + convertTime12to24(time) + ':00';
  return result;
};
