import axios from 'axios';
let API_URL = 'https://kwicktable.com/Api';

// fetching with axios reusable component to fetch in postWithAxios.js

/**
 * Function that helps reduce code when fetching with axios
 *
 */
export default axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json',
  },
});
