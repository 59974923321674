import { Card, Image } from 'semantic-ui-react';
import './components.css';
import { LongText } from './Helpers/LongText';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { GetPhone } from './Helpers/GetPhone';

export const CardInfo = ({ data }) => {
  const items = data;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 10000000, min: 1950 },
      items: 10,
    },
    desktop: {
      breakpoint: { max: 1950, min: 1331 },
      items: 6,
    },
    desktop1: {
      breakpoint: { max: 1330, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1023, min: 900 },
      items: 4,
    },
    mobile2: {
      breakpoint: { max: 899, min: 500 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 499, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      additionalTransfrom={-2 * 10}
      responsive={responsive}
      autoPlaySpeed={10000}
      keyBoardControl={true}
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={['tablet', 'mobile']}
      dotListClass="custom-dot-list-style"
      itemClass="carouselItem"
      partialVisible={true}
    >
      {items?.slice(0, 15).map((item) => {
        return (
          <Link to={`/reservation/${item.name}`} key={item.id}>
            <CardFunction
              key={item.id}
              itemId={item.id}
              fname={item.fname}
              city={item.city}
              phone={item.phone}
              name={item.name}
              state={item.state}
            />
          </Link>
        );
      })}
    </Carousel>
  );
};

function CardFunction({ fname, name, phone, state, city, itemId }) {
  var styles = {
    img: {
      width: '200px',
      height: '140px',
      paddingTop: 0,
      marginTop: 0,
    },
  };
  const storePhone = GetPhone(phone);
  return (
    <Card key={itemId} className="carouselItems">
      <Image
        style={styles.img}
        src={`https://${name}.kwickmenu.com/img/logo.png`}
      />
      <Card.Content>
        <Card.Header>
          <LongText className="card-title" content={fname} limit={26} />
        </Card.Header>
        <Card.Meta>
          <span className="date">Phone: {storePhone}</span>
        </Card.Meta>
        <Card.Description>
          {city}, {state}
        </Card.Description>
      </Card.Content>
    </Card>
  );
}
