import React, { useContext } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import { DisplayHours, GetHours } from './Helpers/GetHours';
import { StoreContext } from './context/store-context';
/**
 * @param  {boolean} isOpen true or false if this model should open
 * @param  {function} togglemodal this function takes a param of boolean type to open or close modal.
 */
export const StoreInfoModal = ({ isOpen, togglemodal }) => {
  const storeCtx = useContext(StoreContext);

  return (
    <>
      <Modal
        closeIcon
        className="modal"
        dimmer="blurring"
        onClose={() => {
          if (isOpen) {
            togglemodal(false);
          }
        }}
        open={isOpen}
      >
        <Modal.Header>Store Information</Modal.Header>
        <Modal.Content>
          <h5>Location</h5>
          <p style={{ fontSize: '1.2em' }}>
            {' '}
            <Icon color="red" name="map marker alternate" />{' '}
            {storeCtx?.bizStoreData?.address}, {storeCtx?.bizStoreData?.city},{' '}
            {storeCtx?.bizStoreData?.state} {storeCtx?.bizStoreData?.zip}, USA
          </p>
          <h5>Store Hours</h5>

          {storeCtx?.isOffline ? (
            <h5>Store Offline</h5>
          ) : (
            <DisplayHours
              hours={GetHours(
                storeCtx?.storeData[0]?.store_time
                  ? storeCtx?.storeData[0]?.store_time
                  : '0'
              )}
            />
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};
