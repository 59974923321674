import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { StoreDetails, Home } from '../components/screens/index';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
/**
 * Routes this application has Home = /, Reservation = storeDetails and waitlist = storeDetails
 * waitlist route locks user in waitlist mode only.
 * @component
 * <RoutesComponent/>
 */
export const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/reservation/:id" element={<StoreDetails />} />
        <Route exact path="/waitlist/:id" element={<StoreDetails />} />
      </Routes>
    </BrowserRouter>
  );
};
