// everything related to making reservation
// bug hours dont account for 30mins
import React, { useState } from 'react';

import './components.css';
import { Button } from 'semantic-ui-react';
import { ReservationModal } from './ReservationModal';
/**
 * @component
 *
 * @example
 * import {Times} from './components'
 * 
 * <Times timeData={timeData}
  storeName={storeName}
  siteName={siteName}
  people={people}
  date={date}
  port={port}
  storePhone={storePhone}
   />

 */
export const Times = ({
  timeData,
  storeName,
  siteName,
  people,
  date,
  port,
  storePhone,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const [timeSelected, setTimeSelected] = useState('');
  /**
   * @param  {boolean} isVisible true or false
   * this function set isVisible to true or false
   */
  const handleIsVisible = (isVisible) => {
    setIsVisible(isVisible);
  };

  return (
    <div style={{ padding: 5, height: '100%' }}>
      {timeData?.map((item) =>
        item === 'No Time Available' ? (
          <h1>No Time Available Store is closed</h1>
        ) : (
          <Button
            style={{ margin: 2 }}
            color="youtube"
            key={item}
            onClick={() => {
              setIsVisible(true);
              setTimeSelected(item);
            }}
          >
            {item}
          </Button>
        )
      )}

      {
        <ReservationModal
          isOpen={isVisible}
          storeName={storeName}
          siteName={siteName}
          toggleModal={handleIsVisible}
          timeSelected={timeSelected}
          date={date}
          people={people}
          port={port}
          storePhone={storePhone}
        />
      }
    </div>
  );
};
