import React, { useContext } from 'react';
import { Container, Segment, Dimmer, Loader } from 'semantic-ui-react';
import logo from '../../../assets/kwicktable-logo.png';
import './splashScreen.css';
import { StoreContext } from '../../context/store-context';
/**
 * This function renders the splash screen
 * @returns {JSX}
 */
export const SplashScreen = () => {
  const storeCtx = useContext(StoreContext);

  return (
    <div className="splash-container">
      <div className=" bouncy-castle ">
        <img className="logo" src={logo} alt="loading" />
        <div className="shadow"></div>
      </div>
      {storeCtx.isLoading || storeCtx.isLoading2 ? <h3>Loading</h3> : null}
    </div>
  );
};
