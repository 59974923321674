import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Icon } from 'semantic-ui-react';
/**
 * returns jsx to render the pin on the map
 */
const LocationPin = () => (
  <div className="pin">
    <Icon color="red" size="big" name="map marker alternate" />
  </div>
);
/**
 * This file utilizes google api to return jsx and render the store location map
 * @component
 * @example
 *
 * <Map location={'134532,234564'} zoomlevel={16}/>
 *
 */
const Map = ({ location, zoomLevel }) => {
  const createMapOptions = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: true,
    rotateControl: false,
    fullscreenControl: false,
  };
  return (
    <div className="google-map custom-map">
      <GoogleMapReact
        options={createMapOptions}
        bootstrapURLKeys={{ key: 'AIzaSyA87mSBJCrB2YnoSAk2Jmn_q-CshG2QiFU' }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin lat={location.lat} lng={location.lng} />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
