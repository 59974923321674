import React from 'react';
import { Container, Grid, Header, List, Segment } from 'semantic-ui-react';

/**
 * Component for showing Footer of the website in desktop mode.
 * File return jsx
 *
 * @component
 * @example
 * Import {Footer} from './components'
 *
 * <Footer/>
 */
export const Footer = () => {
  return (
    <Segment
      inverted
      vertical
      style={{
        paddingBottom: '10em',
        marginTop: '10%',
        width: '100%',
        position: 'relative',
        left: 0,
        bottom: 0,
        fontSize: '1.2em',
      }}
    >
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header inverted as="h3" content="Contact Us" />
              <List link inverted>
                <List.Item as="a" href="https://www.kwickpos.com/">
                  Kwickpos.com
                </List.Item>
                <List.Item as="a">888 355-6996</List.Item>
                <List.Item as="a">support@kwickpos.com</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h3" content="Services" />
              <List link inverted>
                <List.Item as="a">POS Services</List.Item>
                <List.Item as="a">FAQ</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <Header as="h3" inverted>
                kwicktable Powered by Kwickpos
              </Header>
              <p>Make your special reservation now.</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};
