import React, { useContext } from 'react';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Dimmer,
  Segment,
  Loader,
} from 'semantic-ui-react';
import Confetti from 'react-confetti';
import { useWindowSize } from '@react-hook/window-size';
import './Home.css';
import { HomeData } from '../../../components/HomeData';
import Background from '../../../assets/redbackground.png';
import Fade from 'react-reveal/Fade';
import { SearchComponent } from '../../../components/Search';
import { RegistrationModal } from '../../../components/RegistrationModal';
import { LoginModal } from '../../../components/Login';

import { NavBar } from '../../../components/Navbar';
import { createMedia } from '@artsy/fresnel';
import { SplashScreen } from '../SplashScreen/SplashScreen';
import { Footer } from '../../../components/Footer';
import { StoreContext } from '../../../components/context/store-context';
const AppMedia = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 828,
    computer: 1024,
  },
});

const styles = {
  segment: {
    textAlign: 'center',
    width: '100%',
    height: '100%',
    position: 'sticky',
    padding: '1em 0em',
    backgroundImage: `url(${Background})`,
    zIndex: '999',
    textAlign: 'center',
  },
  header1: {
    fontSize: '2em',
    fontWeight: 'normal',
    marginBottom: 0,
    marginTop: '1.5em',
  },
  header2: {
    fontSize: '1.5em',
    fontWeight: 'normal',
    marginTop: '0.5em',
  },
};
/**
 * Home is the page that renders in Home page
 * Has two layouts mobile and desktop
 * @component
 * <Home/>
 */
export const Home = () => {
  const { MediaContextProvider, Media } = AppMedia;
  const leftItems = [<LoginModal />, <RegistrationModal />];
  const rightItems = [<LoginModal />, <RegistrationModal />];

  const storeCtx = useContext(StoreContext);
  const { width, height } = useWindowSize();
  if (storeCtx.isLoading2) {
    return <SplashScreen />;
  }
  let mobile = (
    <div className="background-image">
      <Confetti width={width} height={height} />
      <div
        style={{
          justifyContent: 'center',
          textAlign: 'center',
          alignContent: 'center',
          paddingTop: '40%',
        }}
      >
        <Header as="h1" inverted content="KwickTable" />
        <Header
          as="h2"
          content="Make your Reservation"
          inverted
          style={styles.header2}
        />
        <SearchComponent />
      </div>
    </div>
  );

  let desktop = (
    <NavBar leftItems={leftItems} rightItems={rightItems}>
      <div style={styles.segment}>
        <Confetti width={width} height={'400'} />
        <Header
          as="h1"
          content="KwickTable"
          inverted
          style={{ ...styles.header1, fontSize: '4em', marginTop: '3em' }}
        />
        <Header
          as="h2"
          content="Make your Reservation"
          inverted
          style={{
            ...styles.header2,
            fontSize: '1.7em',
            marginTop: '1.5em',
          }}
        />
        {}
        <SearchComponent />
      </div>

      <Segment style={{ padding: '8em 0em' }} vertical>
        <Header as="h3" style={{ fontSize: '2em', paddingLeft: 20 }}>
          Make a reservation at any of our stores
        </Header>
        <Fade right>
          <HomeData />
        </Fade>
      </Segment>
      <Fade top>
        <Segment style={{ padding: '0em' }} vertical>
          <Grid celled="internally" columns="equal" stackable>
            <Grid.Row textAlign="center">
              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                <Header as="h3" style={{ fontSize: '2em' }}>
                  Takeout
                </Header>

                <p style={{ fontSize: '1.33em' }}>
                  Call Us <Icon color="black" name="phone" size="large" />
                </p>
              </Grid.Column>

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                <Header as="h3" style={{ fontSize: '2em' }}>
                  Delivery
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                  <Icon color="black" name="car" size="large" />
                  Half of the restaurants offer delivery
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Fade>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Header as="h3" style={{ fontSize: '2em', textAlign: 'center' }}>
          Order Online
        </Header>
        <Fade right>
          <HomeData />
        </Fade>
        <Fade top>
          <Container text>
            <Divider
              as="h4"
              className="header"
              horizontal
              style={{ margin: '3em 0em', textTransform: 'uppercase' }}
            >
              <a href={`https://kwickpos.com/`}> Become a Partner and Join</a>
            </Divider>

            <Header as="h3" style={{ fontSize: '2em' }}>
              Did We Tell You About Our Point of Sales services?
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Join the more than 2,000 restaurants which have opted for our POS
              services.
            </p>
            <Button as="a" size="large">
              <a href={`https://kwickpos.com/`}>
                Ask about our most popular POS features.
              </a>
            </Button>
          </Container>
        </Fade>
      </Segment>
      <Footer />
    </NavBar>
  );
  return (
    <MediaContextProvider>
      <Media at="mobile">{mobile}</Media>
      <Media greaterThan="mobile">{desktop}</Media>
    </MediaContextProvider>
  );
};
