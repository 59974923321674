// everything related to finding a table
import React, { useEffect, useState, useContext } from 'react';

import { Times } from './Times';
import { getReservationHours } from './Helpers/GetHours';

import { Icon, Dropdown, Container, Form, Button } from 'semantic-ui-react';
import { StoreContext } from './context/store-context';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { ReservationModal } from './ReservationModal';

/**
 * returns a jsx component that renders selections in the form of a calendar, amount of guests and reservation time
 * For this taz pass all this require parameters.
 * @component
 * @example
 *  hours = [strings];
 *  port = '12345';
 *  storePhone = '123-123-1234;
 *  serverTime = '14:44';
 *  storeName= string
 *  siteName =string
 *  isOffline= boolean
 *  screenMode= string
 *
 *   <FindTable screenMode = 'mobile' port={port} storePhone={storePhone} serverTime={serverTime} isOffline={false}... >
 *
 */
export const FindTable = ({
  screenMode,
  hours,
  port,
  storePhone,
  serverTime,
  storeName,
  siteName,
  isOffline,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [isVisible, setIsVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [people, setPeople] = useState();
  const [time, setTime] = useState('');
  const [times, setTimes] = useState([]);
  const [isTimeSelected, setIsTimeSelected] = useState(false);

  const storeCtx = useContext(StoreContext);

  // checks if isDisable
  useEffect(() => {
    storeCtx.getIsDisabled(isDisabled);
  }, []);

  const DateBtn = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      ref={ref}
      onClick={onClick}
      className="icon"
      labelPosition="left"
      icon
      style={{
        borderRadius: 10,
        textAlign: 'center',
        width: '18em',
        fontSize: '15px',
        marginTop: 2,
      }}
    >
      <Icon disabled name="calendar alternate" /> {value}
    </Button>
  ));

  let arrayOfGuests = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
  ];
  const stateOptions = arrayOfGuests.map((item, index) => ({
    key: [index + 1],
    text: item,
    value: item,
  }));
  /**
   * @component
   * This component returns the UI of people selection
   * @example
   * <SearchPeopleSelection />
   */
  const SearchPeopleSelection = () => {
    return (
      <Dropdown
        labeled
        button
        className="icon"
        style={{
          borderRadius: 10,
          textAlign: 'center',

          width: '18em',
          fontSize: '15px',
          marginTop: 2,
        }}
        scrolling
        options={stateOptions}
        icon="users"
        onChange={handlePeopleChange}
        value={people}
      />
    );
  };

  let arrayOfAvailableTimes = getReservationHours(startDate, hours, serverTime);
  /**
   * Thus useEffect checks if there are times if no times then we disable the FindTable component
   */
  useEffect(() => {
    if (!!arrayOfAvailableTimes) {
      if (
        arrayOfAvailableTimes[0] === 'No Time Available' ||
        arrayOfAvailableTimes[0] === ''
      ) {
        storeCtx.getIsDisabled(true);
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [arrayOfAvailableTimes]);
  // Creates new array that has keys, text and value for a better way to map and with required key
  const availableTimes = arrayOfAvailableTimes?.map((item, index) => ({
    key: [index],
    text: item,
    value: item,
  }));

  const TimeSelection = () => {
    return (
      <Dropdown
        labeled
        button
        className="icon"
        style={{
          borderRadius: 10,
          textAlign: 'center',

          width: '18em',
          fontSize: '15px',
          marginTop: 2,
        }}
        icon="clock"
        scrolling
        options={availableTimes}
        onChange={handleTimeChange}
        text={time}
      />
    );
  };
  // first render show time and guests
  useEffect(() => {
    setPeople('1');
    setTime(arrayOfAvailableTimes[0]);
  }, []);
  /**
   * @component
   * This component is in charge of rendering and capturing the data from the calendar
   * @example
   * <SearchDateSelections/>
   */
  const SearchDateSelections = () => {
    return (
      <DatePicker
        selected={startDate}
        customInput={<DateBtn />}
        onChange={handleDateChange}
        popperPlacement="top"
        dropdownMode="select"
        dateFormat="MM-dd-yyyy"
        minDate={new Date()}
        maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 3)}
      />
    );
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setTimes([]);
    setTime('Time');
    setIsTimeSelected(false);
  };
  const handlePeopleChange = (e, { value }) => setPeople(value);
  const handleTimeChange = (e, { value }) => {
    setIsTimeSelected(true);
    setTime(value);
  };
  const handleIsVisible = (isVisible) => {
    setIsVisible(isVisible);
  };

  const FindTableButton = () => {
    var hours4store = getReservationHours(startDate, hours, serverTime);

    setTimes(hours4store);
  };
  const ReserveNow = () => {
    setIsVisible(true);
  };

  function TimeSelected({ times }) {
    var noHours = false;

    if (times === undefined || times[0] === 'No Time Available') {
      noHours = true;
    }

    return (
      <div>
        {noHours ? (
          <h2>No Time Avaliable</h2>
        ) : (
          <Times
            storeName={storeName}
            siteName={siteName}
            timeData={times}
            date={startDate}
            people={people}
            port={port}
            storePhone={storePhone}
          />
        )}
      </div>
    );
  }

  if (screenMode === 'mobile') {
    return (
      <div style={{ textAlign: 'center' }}>
        <hr />
        <Form.Group size="md">
          <SearchDateSelections vertical />

          <TimeSelection />
          <SearchPeopleSelection />
          <hr />
          <Button.Group vertical>
            <Button
              disabled={isOffline ? isOffline : isDisabled}
              style={{
                borderRadius: 10,
                textAlign: 'center',
                width: '18em',
                fontSize: '15px',
                marginTop: 2,
              }}
              color="red"
              onClick={!isTimeSelected ? FindTableButton : ReserveNow}
            >
              {!isTimeSelected ? 'Find Table' : 'Reserve now'}
            </Button>
          </Button.Group>
        </Form.Group>

        {isOffline ? null : (
          <ReservationModal
            storeName={storeName}
            siteName={siteName}
            isOpen={isVisible}
            timeSelected={time}
            toggleModal={handleIsVisible}
            timeData={times}
            date={startDate}
            people={people}
            port={port}
            storePhone={storePhone}
          />
        )}

        <TimeSelected times={times} />
      </div>
    );
  } else {
    return (
      <Container style={{ textAlign: 'center' }}>
        <Form.Group size="md">
          <SearchDateSelections vertical />

          <TimeSelection />
          <SearchPeopleSelection />

          <hr />
          <Button.Group vertical>
            <Button
              disabled={isDisabled}
              style={{
                borderRadius: 10,
                textAlign: 'center',
                width: '18em',
                fontSize: '18px',
                marginTop: 2,
              }}
              color="red"
              onClick={!isTimeSelected ? FindTableButton : ReserveNow}
            >
              {!isTimeSelected ? 'Find Table' : 'Reserve now'}
            </Button>
          </Button.Group>
        </Form.Group>

        <ReservationModal
          storeName={storeName}
          siteName={siteName}
          isOpen={isVisible}
          timeSelected={time}
          toggleModal={handleIsVisible}
          timeData={times}
          date={startDate}
          people={people}
          port={port}
          storePhone={storePhone}
        />
        <TimeSelected times={times} />
      </Container>
    );
  }
};
