import React from 'react';

import { Button, Icon, Modal, Form, Menu } from 'semantic-ui-react';

const RegisterForm = () => {
  return (
    <Form>
      <Form.Group unstackable widths={2}>
        <Form.Input label="First name" placeholder="First name" />
        <Form.Input label="Last name" placeholder="Last name" />
      </Form.Group>
      <Form.Group widths={1}>
        <Form.Input label="Email Address" placeholder="Email Address" />
        <Form.Input label="Phone" placeholder="Phone" />
      </Form.Group>
      <Form.Checkbox label="I agree to the Terms and Conditions" />
    </Form>
  );
};

export const RegistrationModal = () => {
  const [firstOpen, setFirstOpen] = React.useState(false);
  const [secondOpen, setSecondOpen] = React.useState(false);

  return (
    <>
      <Menu.Item>
        <Button onClick={() => setFirstOpen(true)}>Register</Button>
      </Menu.Item>

      <Modal
        onClose={() => setFirstOpen(false)}
        onOpen={() => setFirstOpen(true)}
        open={firstOpen}
      >
        <Modal.Header>Register</Modal.Header>
        <Modal.Content image>
          {/* <div className='image'>
              <Icon name='right arrow' />
            </div>
            <Modal.Description>
              <p>We have more to share with you. Follow us along to modal 2</p>
            </Modal.Description> */}
          <RegisterForm />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setSecondOpen(true)} primary>
            Register <Icon name="right chevron" />
          </Button>
        </Modal.Actions>

        <Modal
          onClose={() => setSecondOpen(false)}
          open={secondOpen}
          size="small"
        >
          <Modal.Header>Registration Succesful</Modal.Header>
          <Modal.Content>
            <p>That's everything!</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon="check"
              content="All Done"
              onClick={() => setSecondOpen(false)}
            />
          </Modal.Actions>
        </Modal>
      </Modal>
    </>
  );
};
