import { CardInfo } from './Card';
import { StoreContext } from './context/store-context';
import {
  Dimmer,
  Loader,
  Image,
  Segment,
  Icon,
  Header,
  Container,
} from 'semantic-ui-react';
import React, { useContext } from 'react';
/**
 *
 * @return {}
 */

/**
 * returns a jsx component that renders Stores cards in home page for desktop only
 * From this component I request an api call using the useQuery and it fails I return Error page
 * @component
 * @example
 *
 * return (
 *   <HomeData >
 * )
 */
export const HomeData = () => {
  const storeCtx = useContext(StoreContext);

  if (storeCtx.isLoading2) {
    return (
      <Segment>
        <Dimmer active inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>

        <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />
      </Segment>
    );
  }

  if (storeCtx.isLoading2 === false && storeCtx?.allStoresData !== '') {
    const HomeData = storeCtx?.allStoresData?.filter((store) =>
      store.state.toLowerCase().includes('tx')
    );
    return <CardInfo data={HomeData} />;
  }

  return (
    <Container textAlign="center" style={{ paddingTop: 50 }}>
      <Header as="h2" icon>
        <Icon name="settings" />
        Error Please try again later
        <Header.Subheader>We are working on it</Header.Subheader>
      </Header>
    </Container>
  );
};
