import React from "react";

import { Button, Icon, Modal, Form, Menu } from "semantic-ui-react";

export const LoginModal = () => {
  const [firstOpen, setFirstOpen] = React.useState(false);
  const [secondOpen, setSecondOpen] = React.useState(false);

  const handleLogin = (event) => {
    event.preventDefault();
    const { target } = event;

    console.log("target.Email.value", target[0].value);
    console.log("target.pass.value", target[1].value);
  };

  const LoginForm = () => {
    return (
      <Form onSubmit={handleLogin}>
        <Form.Group unstackable widths={1}>
          <Form.Input label="Email" placeholder="name@mail.com" />
        </Form.Group>
        <Form.Group widths={1}>
          <Form.Input label="Password" placeholder="password" />
        </Form.Group>
        <Form.Checkbox
          label="I agree to the Terms and Conditions"
          error={{
            content: "You must agree to the terms and conditions",
            pointing: "left",
          }}
        />

        <Button type="submit">Submit</Button>
      </Form>
    );
  };

  return (
    <>
      <Menu.Item>
        <Button as="a" onClick={() => setFirstOpen(true)}>
          Login
        </Button>
      </Menu.Item>

      <Modal
        onClose={() => setFirstOpen(false)}
        onOpen={() => setFirstOpen(true)}
        open={firstOpen}
      >
        <Modal.Header>Login</Modal.Header>
        <Modal.Content image>
          <LoginForm />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setSecondOpen(true)} primary>
            Login <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
