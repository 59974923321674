import { createContext, useState, useEffect } from 'react';
import { fetchStoreInfo, fetchStoresInfo } from '../../api/PostsWithAxios';
export const StoreContext = createContext({
  isLoading: true,
  doesNotExist: '',
  storeName: '',
  bizStoreData: '',
  storeData: '',
  active: '0',
  waitListCount: '0',
  isDisabled: false,
  isOffline: false,
  isLoading2: true,
  allStoresData: '',
  isSplash: true,
  getSetId: (data) => {},
  getIsDisabled: (data) => {},
  getDoesNotExist: (data) => {},
  getWaitListCount: (data) => {},
  getSetIsSplash: () => {},
});

const StoreContextProvider = ({ children }) => {
  const [allStoresData, setAllStoresData] = useState('');
  const [storeData, setStoreData] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [active, setActive] = useState('0');
  const [waitListCount, setWaitListCount] = useState('0');
  const [id, setId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isSplash, setIsSplash] = useState(true);
  const [isOffline, setIsOffline] = useState(false);
  const [bizStoreData, setBizStoreData] = useState();
  const [doesNotExist, setDoesNotExist] = useState(false);

  const setAllData = () => {
    if (storeData) {
      setDoesNotExist(!!storeData?.NotExistingStore);

      setIsOffline(false);
      if (typeof storeData === 'string') {
        setIsOffline(storeData?.includes('storeOffline'));
      } else {
        setIsOffline(false);
      }

      setWaitListCount(storeData?.active ? storeData?.active : '0');

      // if (storeData[0]?.name) setId(storeData[0]?.name);
    }
  };
  const getSetId = async (data) => {
    setId(data);
  };
  const getSetIsSplash = (data) => {
    setIsSplash(data);
  };
  const getIsDisabled = (data) => {
    setIsDisabled(data);
  };
  const getWaitListCount = (data) => {
    setWaitListCount(parseInt(data) + parseInt(waitListCount));
  };

  const getBizStoreInfo = async (data, id) => {
    const store = data?.find(
      (index) =>
        index?.name?.toLowerCase() === id.toLowerCase().replace(' ', '')
    );

    setBizStoreData(!!store ? store : null);

    // checks if its null, undefined or empty and returns data (ternary  if statement)
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      site_name: !!store?.name ? store.name : '0',
      store_port: !!store?.port ? store.port : '0',
      slaveport: !!store?.slaveport ? store.slaveport : '0',
    };
  };

  const getStoreInfo = async () => {
    if (id && allStoresData) {
      setIsLoading(true);
      try {
        let apiReadyPort = await getBizStoreInfo(allStoresData, id);
        const storeInfo = await fetchStoreInfo(apiReadyPort);
        setStoreData(storeInfo);
      } catch (error) {
        console.log('ERROR', error);
      }

      setIsLoading(false);
    }
  };

  const getStoresInfo = async () => {
    try {
      setIsLoading2(true);
      const storesInfo = await fetchStoresInfo();
      setAllStoresData(storesInfo);
    } catch (error) {
      console.log('ERROR', error);
    }
    setIsLoading2(false);
  };

  useEffect(() => {
    setAllData();
  }, [storeData, isOffline, id, allStoresData]);

  useEffect(() => {
    getStoresInfo();
  }, []);

  useEffect(() => {
    getStoreInfo();
    setAllData();
  }, [id, allStoresData]);

  const value = {
    isLoading: isLoading,
    doesNotExist: doesNotExist,
    storeName: storeData?.store_name,
    bizStoreData: bizStoreData,
    storeData: storeData,
    active: active,
    waitListCount: waitListCount,
    isDisabled: isDisabled,
    isOffline: isOffline,
    isLoading2: isLoading2,
    allStoresData: allStoresData,
    isSplash: isSplash,
    getSetId: getSetId,
    getWaitListCount: getWaitListCount,
    getIsDisabled: getIsDisabled,
    getSetIsSplash: getSetIsSplash,
  };

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export default StoreContextProvider;
