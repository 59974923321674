// everything related to finding a table
import React, { useEffect, useContext, useState } from 'react';
import { StoreContext } from './context/store-context';

import { Search, Label, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
/**This file contains all related to the search component in home page
 *
 * @component
 * <SearchComponent />
 */
export const SearchComponent = () => {
  const storeCtx = useContext(StoreContext);

  const storesData = storeCtx?.allStoresData;
  const [searchTerm, setSearchTerm] = useState('');

  const [EnableItems, setEnableItems] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  /**
   * Filters the data using useEffect to avoid this function running multiple times, only when the dependencies change
   */
  useEffect(() => {
    var filterData = storesData?.filter((item) =>
      item.fname.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setSearchResults(filterData.slice(0, 3));
  }, [searchTerm, storesData]);
  /**
   * This function will set the search variables to filter the data of stores
   * @param  {object} event will have the event of user input
   */
  const handleChange = (event) => {
    setEnableItems(true);
    setSearchTerm(event.target.value);
    setEnableItems(true);
  };
  /**
   * This function will return jsx that renders the filter data the props are the store details
   * Function is later used in Search
   *
   */
  const resultRenderer = (props) => {
    setEnableItems(false);
    return (
      <Link to={`/reservation/${props.name}`}>
        <div
          style={{
            display: 'flex',
            flex: 'wrap',
            alignSelf: 'center',
            height: 'fit-content',
            width: 'fit-content',
          }}
        >
          <Label>
            <Icon color="black" name="food" size="large" />
            {props.fname}
            <Label>
              <Icon color="black" name="map marker alternate" size="small" />
              {props.city}
            </Label>
          </Label>
        </div>
      </Link>
    );
  };

  return (
    <Search
      style={{
        display: 'flex',
        flex: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
      loading={EnableItems}
      onSearchChange={handleChange}
      results={searchResults}
      value={searchTerm}
      resultRenderer={resultRenderer}
      placeholder="Find Restaurant"
    />
  );
};
