import { createMedia } from '@artsy/fresnel';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Menu, Label } from 'semantic-ui-react';

const AppMedia = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});
/**
 * This file as all related to the Navbar component both for mobile and desktop
 *
 */

const NavbarMobile = () => {
  const navigate = useNavigate();

  const GoHome = () => {
    navigate('/');
  };

  return (
    <Menu fixed="top" inverted>
      <Menu.Menu position="left">
        <Menu.Item as="a" onClick={GoHome}>
          <Label color="red" ribbon>
            <p style={{ fontSize: '12px' }}>KwickTable</p>
          </Label>
        </Menu.Item>
      </Menu.Menu>
      <Menu.Menu position="right">
        <Menu.Item onClick={GoHome} style={{ fontSize: '1.2em' }}>
          Home
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

const NavbarDesktop = () => {
  const navigate = useNavigate();

  const GoHome = () => {
    navigate('/');
  };

  return (
    <Menu fixed="top" inverted>
      <Menu.Menu position="left">
        <Menu.Item as="a" onClick={GoHome}>
          <Label color="red" ribbon>
            <p style={{ fontSize: '1.2em' }}>KwickTable</p>
          </Label>
        </Menu.Item>
      </Menu.Menu>
      <Menu.Menu position="right">
        <Menu.Item onClick={GoHome} style={{ fontSize: '1.2em' }}>
          Home
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

const NavBarChildren = ({ children }) => (
  <div style={{ marginTop: '1em' }}>{children}</div>
);

export const NavBar = ({ children }) => {
  const mediaStyles = AppMedia.createMediaStyle();
  const { Media } = AppMedia;

  return (
    <div>
      <style>{mediaStyles}</style>
      <Media at="mobile">
        <NavbarMobile />
        <NavBarChildren>{children}</NavBarChildren>
      </Media>

      <Media greaterThan="mobile">
        <NavbarDesktop />
        <NavBarChildren>{children}</NavBarChildren>
      </Media>
    </div>
  );
};
