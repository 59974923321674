import React, { useState, useEffect, useContext } from 'react';
import './StoreDetails.css';
import { FindTable } from '../../../components/FindTable';
import { SplashScreen } from '../SplashScreen/SplashScreen';
import { useParams, useNavigate } from 'react-router-dom';
import MapSection from '../../../components/Map';
import { GetGeo } from '../../Helpers/GetGeo';
import { GetPhone } from '../../Helpers/GetPhone';
import { GetHours } from '../../Helpers/GetHours';
import { Footer } from '../../../components/Footer';
import Confetti from 'react-confetti';
import { useWindowSize } from '@react-hook/window-size';
import {
  Segment,
  Loader,
  Header,
  Dimmer,
  Image,
  Container,
  Grid,
  Button,
  Icon,
  Label,
  Message,
} from 'semantic-ui-react';
import { NavBar } from '../../../components/Navbar';
import { useLocation } from 'react-router-dom';
import { StoreContext } from '../../../components/context/store-context';
import { WaitingListModal } from '../../../components/WaitingListModal';
import { StoreInfoModal } from '../../../components/StoreInfoModal';
// styles object
const styles = {
  coverPicturePositionMobile: {
    filter: 'brightness(50%)',
    position: 'relative',

    objectFit: 'cover',
    width: '100%',
    minHeight: 300,
    overflow: 'hidden',
    marginTop: 5,
    top: -18,
  },
  coverPicturePosition: {
    filter: 'brightness(50%)',
    position: 'relative',

    objectFit: 'cover',
    width: '100%',
    minHeight: 400,
    overflow: 'hidden',
    marginTop: 5,
    top: -18,
  },
  slogan: {
    left: '0',
    position: 'absolute',
    textAlign: 'center',
    top: 75,
    width: '100%',
  },
  sloganText1: {
    fontWeight: 'bold',
    fontSize: 28,
    color: 'red',
  },
  sloganText2: {
    fontWeight: 'bold',
    fontSize: 42,
    color: '#ffffff',
  },
  circleImg: {
    borderRadius: '50%',
    backgroundColor: 'white',
    width: '14em',
    height: '14em',
    objectFit: 'contain',
    objectPosition: '50% 50%',
    position: 'absolute',
    top: 320,
    left: 20,
  },
  circleImgMobile: {
    borderRadius: '50%',
    backgroundColor: 'white',
    width: '10em',
    height: '10em',
    objectFit: 'contain',
    objectPosition: '50% 50%',
    position: 'absolute',
    top: 230,
    left: 20,
  },
  desktopCover: {
    left: 0,
    top: 0,
    borderRadius: 16,
    overflow: 'hidden',
    width: '100%',
    minHeight: 350,
    backgroundColor: '#f7f7f7',
  },
  coverMobile: {
    left: 0,
    top: 0,
    borderRadius: 16,
    overflow: 'hidden',
    width: '100%',
    minHeight: 180,
    backgroundColor: '#f7f7f7',
  },

  storeDetails: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-end',

    top: -70,
  },
  waiting: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-end',
    top: -25,
  },
  reservation: {
    backgroundPosition: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
};
/**
 * This file renders the storeDetails as a page
 */
export const StoreDetails = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [waitListCount, setWaitListCount] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const storeCtx = useContext(StoreContext);
  const { width, height } = useWindowSize();

  //checks if url contains waitlist to redirect to waitlist
  const url = useLocation();
  let urlPath = url.pathname;
  let arrayUrlStore = urlPath.split('/');
  let storeUrl = arrayUrlStore[2];
  let isWaiting = url.pathname.includes('waitlist');

  // useffect to set id as id in storeCtx
  useEffect(() => {
    storeCtx.getSetId(id);
  }, [id]);

  if (storeCtx.isLoading) {
    return <SplashScreen />;
  }

  /**
   * responsible for making the Wait list modal open or close
   * @param  {boolean} isVisible - true or false
   */
  const handleIsVisible = (isVisible) => {
    setIsVisible(isVisible);
  };
  /**
   * responsible for making the store details modal open or close
   * @param  {boolean} isVisible - true or false
   */
  const handleToggle = (isVisible) => {
    setIsToggle(isVisible);
  };

  if (storeCtx?.doesNotExist) {
    return (
      <Container textAlign="center" style={{ paddingTop: 50 }}>
        <Header as="h2" icon>
          <Icon name="settings" />
          Store does not exist
          <Header.Subheader>Store Not Available</Header.Subheader>
          <Button onClick={() => navigate('/')}>Home</Button>
        </Header>
      </Container>
    );
  }
  // Another way too check if is a mobile or desktop
  let isMobile = false;
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    isMobile = true;
  }

  //JSX
  return (
    <div>
      <NavBar>
        <div>
          <img
            style={
              isMobile
                ? styles.coverPicturePositionMobile
                : styles.coverPicturePosition
            }
            src={`https://${storeCtx?.bizStoreData?.name}.kwickmenu.com/img/online.jpg`}
            onError={(event) => {
              event.target.src =
                'https://img.traveltriangle.com/blog/wp-content/uploads/2019/06/Restaurants-in-Patiala.jpg';
              event.onerror = null;
            }}
            alt="Cover"
            height={isMobile ? '200' : '400'}
          />
          <div style={isMobile ? styles.slogan : styles.slogan}>
            <h1 style={isMobile ? styles.sloganText1 : styles.sloganText1}>
              Reserve at
            </h1>
            <h1 style={isMobile ? styles.sloganText2 : styles.sloganText2}>
              {storeCtx?.bizStoreData?.fname}
            </h1>
          </div>
          <Container>
            <Image
              style={isMobile ? styles.circleImgMobile : styles.circleImg}
              src={`https://${storeCtx?.bizStoreData?.name}.kwickmenu.com/img/logo.png`}
              onError={(event) => {
                event.target.src =
                  'https://img.traveltriangle.com/blog/wp-content/uploads/2019/06/Restaurants-in-Patiala.jpg';
                event.onerror = null;
              }}
            />
            <Confetti width={width} height={isMobile ? '200' : '400'} />

            <div style={styles.storeDetails}>
              <Button
                icon
                onClick={() => setIsToggle(true)}
                style={{ fontSize: '1.2em' }}
              >
                <Icon name="info circle" />
                Store Details
              </Button>
            </div>
            <StoreInfoModal isOpen={isToggle} togglemodal={handleToggle} />

            <div style={styles.waiting}>
              <Button as="div" labelPosition="right">
                <Button
                  disabled={storeCtx?.isDisabled || storeCtx?.isOffline}
                  icon
                  onClick={() => setIsVisible(true)}
                  style={{ fontSize: '1.2em' }}
                >
                  <Icon name="list" />
                  Waiting List
                </Button>
                <Label as="a" basic pointing="left">
                  {storeCtx.waitListCount}
                </Label>
              </Button>
            </div>
            {!storeCtx?.isOffline && (
              <WaitingListModal
                addWaitListCount={storeCtx.getWaitListCount}
                storeName={storeCtx?.bizStoreData?.name}
                siteName={storeUrl}
                port={storeCtx?.bizStoreData?.port}
                isOpen={isWaiting ? true : isVisible}
                togglemodal={handleIsVisible}
                storePhone={`tel: ${GetPhone(storeCtx?.bizStoreData?.phone)}`}
              />
            )}

            <div
              style={{
                paddingTop: '6%',
                justifyContent: 'space-around',
                height: 700,
              }}
            >
              <Container style={styles.reservation}>
                <h1>Reservation</h1>
                <Grid centered>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      {storeCtx?.isOffline ? (
                        <Message
                          style={{ textAlign: 'center' }}
                          warning
                          size="huge"
                          icon="wifi"
                          header="Store is offline Please check back later"
                          content={`Call store at ${GetPhone(
                            storeCtx?.bizStoreData?.phone
                          )}`}
                        />
                      ) : (
                        <FindTable
                          storeName={storeCtx?.bizStoreData?.name}
                          siteName={storeUrl}
                          screenMode={isMobile ? 'mobile' : 'desktop'}
                          hours={GetHours(storeCtx?.storeData[0]?.store_time)}
                          port={storeCtx?.bizStoreData?.port}
                          storePhone={`tel: ${GetPhone(
                            storeCtx?.bizStoreData?.phone
                          )}`}
                          serverTime={storeCtx?.storeData[0]?.serverTime}
                          isOffline={storeCtx?.isOffline}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div
                  style={{
                    paddingTop: '10%',
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Grid celled="internally">
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <h5 style={{ fontSize: '1.2em' }}>Order Now</h5>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <h5 style={{ fontSize: '1.2em' }}>Order Online</h5>

                        <Button>
                          <a
                            style={{ fontSize: '1.2em' }}
                            href={`https://${storeCtx?.bizStoreData?.name}.kwickmenu.com/`}
                          >
                            Online
                          </a>
                        </Button>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <h5 style={{ fontSize: '1.2em' }}>Takeout</h5>

                        <a
                          href={`tel:${GetPhone(
                            storeCtx?.bizStoreData?.phone
                          )}`}
                          style={{ fontSize: '1.2em' }}
                        >
                          <Icon color="black" name="phone" size="large" />
                          {GetPhone(storeCtx?.bizStoreData?.phone)}
                        </a>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>

                <div style={{ paddingTop: '5%' }}>
                  <h5 style={{ fontSize: '1.2em' }}>Come Visit Us</h5>

                  {!!storeCtx?.storeData[0]?.store_geo && (
                    <MapSection
                      location={GetGeo(storeCtx?.storeData[0]?.store_geo)}
                      zoomLevel={16}
                    />
                  )}
                  <p style={{ fontSize: '1.2em' }}>
                    {' '}
                    <Icon color="red" name="map marker alternate" />{' '}
                    {storeCtx?.bizStoreData?.address},{' '}
                    {storeCtx?.bizStoreData?.city},{' '}
                    {storeCtx?.bizStoreData?.state}{' '}
                    {storeCtx?.bizStoreData?.zip}, USA
                  </p>
                </div>
              </Container>
            </div>
          </Container>
        </div>
      </NavBar>
      <div
        style={{
          paddingTop: '30%',
          bottom: 0,
          width: '100%',
          justifyContent: 'flex-end',
          alignContent: 'flex-end',
        }}
      >
        {!isMobile && <Footer />}
      </div>
    </div>
  );
};
