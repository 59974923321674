import React, { useContext, useState } from 'react';
import { StoreContext } from './context/store-context';
import { RemoveDashes } from './Helpers/GetPhone';
import { useNavigate } from 'react-router-dom';
import './components.css';
import {
  Loader,
  Dimmer,
  Button,
  Form,
  Input,
  Message,
  Modal,
  Grid,
  Dropdown,
  Statistic,
  Icon,
  Label,
} from 'semantic-ui-react';
import { useInput } from './customHooks/use-input';
import { addToWaitListApi, addSmsApi } from '../api/PostsWithAxios';
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size';
import Confetti from 'react-confetti';
/**
 * @component
 * This component returns jsx that renders the waiting list modal form
 * @example
 *
 * <WaitingListModal
 * addWaitListCount=addWaitListCount
  storeName=storeName
  port=port
  isOpen=isOpen
  togglemodal=togglemodal
  storePhone=storePhone
  siteName=siteName/>
 */

export const WaitingListModal = ({
  addWaitListCount,
  storeName,
  port,
  isOpen,
  togglemodal,
  storePhone,
  siteName,
}) => {
  const [isLoadingWaiting, setIsLoadingWaiting] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const [storeResponse, setStoreResponse] = useState();
  const [WaitingList, setWaitingList] = useState();
  let waitingList;
  const storeCtx = useContext(StoreContext);
  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetNameInput,
  } = useInput('text');
  const {
    value: enteredGuests,
    isValid: enteredGuestsIsValid,
    hasError: guestsInputHasError,
    valueChangeHandler: guestsChangeHandler,
    inputBlurHandler: guestsBlurHandler,
    reset: resetGuestsInput,
  } = useInput('dropdown');
  const {
    value: enteredPhone,
    isValid: enteredPhoneIsValid,
    hasError: phoneInputHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    reset: resetPhoneInput,
  } = useInput('phone');

  let formInvalid = false;
  if (enteredNameIsValid && enteredPhoneIsValid && enteredGuests) {
    formInvalid = true;
  }

  let arrayOfGuests = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
  ];
  const stateOptions = arrayOfGuests.map((item, index) => ({
    key: [index + 1],
    text: item,
    value: item,
  }));

  const HandleSubmit = async (event) => {
    event.preventDefault();
    if (!enteredNameIsValid) {
      return;
    }
    setSecondOpen(true);
    // to render to the dom using useState
    setWaitingList({
      user_name: enteredName,
      user_phone: enteredPhone,
      user_guests: enteredGuests,
    });
    // to send to as a post api
    waitingList = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      user_name: enteredName,
      user_phone: RemoveDashes(enteredPhone),
      user_guests: enteredGuests,
      store_port: port === undefined || port === null ? '0001' : port,
      site_name: siteName,
    };
    // to send as a post api for sms
    let smsData = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      site_name: siteName,
      user_phone: RemoveDashes(enteredPhone),
      txt_message:
        'Wait list confirmation for ' +
        waitingList.user_name +
        ' for ' +
        waitingList.user_guests +
        ' guests' +
        ' at ' +
        storeName,
    };

    setSecondOpen(true);
    setIsLoadingWaiting(true);
    try {
      const waitListRes = await addToWaitListApi(waitingList);
      const smsConfirmation = await addSmsApi(smsData);
      //console.log('smsConfirmation', smsConfirmation);
      setStoreResponse(waitListRes);
      setIsLoadingWaiting(false);
      addWaitListCount(1);
    } catch (error) {
      console.log('error', error);
      setStoreResponse(error);
      setIsLoadingWaiting(false);
    }

    resetPhoneInput();
    resetNameInput();
    resetGuestsInput();
  };

  return (
    <div>
      <Modal
        className="modal"
        size="fullscreen"
        dimmer="blurring"
        closeIcon
        onClose={() => {
          if (isOpen) {
            togglemodal(false);
          }
        }}
        centered={false}
        open={isOpen}
        style={{ flex: 2 }}
      >
        <Modal.Header test-id="Modal-header-id">
          <Grid textAlign="left">
            <Grid.Column>
              <Label as="a" color="red" ribbon>
                <p style={{ fontSize: '12px' }}>KwickTable</p>
              </Label>

              <h3 style={{ textAlign: 'center' }}>
                WaitList <Icon size="small" name="users" circular />
              </h3>
              <Statistic.Value style={{ textAlign: 'flex-end' }}>
                Total in wait list:{storeCtx.waitListCount}
              </Statistic.Value>
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Grid textAlign="left">
          <Grid.Column>
            <Form className="waiting-form" onSubmit={HandleSubmit}>
              <h4
                style={{
                  fontSize: '18px',
                  paddingBottom: 6,
                }}
              >
                {storeName}
              </h4>

              <Form.Field inline>
                <Input
                  label={{ icon: 'user', content: 'Name' }}
                  onChange={nameChangeHandler}
                  onBlur={nameBlurHandler}
                  value={enteredName}
                />

                {nameInputHasError && (
                  <Label basic color="red">
                    Please enter a valid name
                  </Label>
                )}
              </Form.Field>
              <Form.Field inline>
                <Input
                  label={{ icon: 'phone', content: 'Phone' }}
                  onChange={phoneChangeHandler}
                  onBlur={phoneBlurHandler}
                  value={enteredPhone}
                />

                {phoneInputHasError && (
                  <Label basic color="red">
                    Please enter a valid phone
                  </Label>
                )}
              </Form.Field>
              <Form.Field inline>
                <Dropdown
                  placeholder="Guests"
                  floating
                  labeled
                  button
                  icon="add user"
                  className="icon"
                  scrolling
                  options={stateOptions}
                  onChange={guestsChangeHandler}
                />

                {guestsInputHasError && (
                  <Label basic color="red">
                    Please enter a valid guest amount
                  </Label>
                )}
              </Form.Field>

              <Button
                disabled={!formInvalid}
                onClick={HandleSubmit}
                type="submit"
                color="red"
              >
                Submit <Icon name="right chevron" />{' '}
              </Button>
            </Form>
          </Grid.Column>
        </Grid>
        <Modal
          closeIcon
          dimmer="blurring"
          onClose={() => setSecondOpen(false)}
          open={secondOpen}
          size="small"
        >
          <Modal.Header>
            <Grid textAlign="left">
              <Grid.Column>
                <Label as="a" color="red" ribbon>
                  <p style={{ fontSize: '12px' }}>KwickTable</p>
                </Label>

                <h3 style={{ textAlign: 'center' }}>
                  <Icon size="small" name="list" circular />
                  Waiting List details
                </h3>
              </Grid.Column>
            </Grid>
          </Modal.Header>

          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <h4
                  style={{
                    fontSize: '18px',
                  }}
                >
                  {storeName}
                </h4>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <hr />
          <Grid textAlign="left">
            <Grid.Column>
              <Modal.Content className="waiting-form">
                {isLoadingWaiting ? (
                  <Dimmer active inverted>
                    <Loader size="large">Loading</Loader>
                  </Dimmer>
                ) : storeResponse === 200 ? (
                  <div>
                    <Confetti width={650} />
                    <Grid stackable>
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <h4>Name: {WaitingList?.user_name}</h4>
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <h4>Phone: {WaitingList?.user_phone}</h4>
                        </Grid.Column>
                        <Grid.Column width={5}>
                          <h4>Guests: {WaitingList?.user_guests}</h4>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Message
                      success
                      header="Success"
                      content="You are now on the waiting list"
                    />
                  </div>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <a href={`tel:${storePhone}`}>
                      <h4>{`We are having some issues, Please call us at ${storePhone}`}</h4>
                    </a>
                  </div>
                )}
              </Modal.Content>
            </Grid.Column>
          </Grid>

          <Modal.Actions>
            <Button
              icon="check"
              content="All Done"
              onClick={() => {
                if (isOpen) {
                  togglemodal(false);
                }
                setSecondOpen(false);
              }}
            />
          </Modal.Actions>
        </Modal>
      </Modal>
    </div>
  );
};
